// this file exists solely to deal with compatibility inconsistencies between `process.env` and `import.meta.env` in shared code
let EnvironmentVariables: any;
if (typeof process !== 'undefined' && process.env) {
  EnvironmentVariables = process.env;
} else if (import.meta?.env) {
  EnvironmentVariables = import.meta.env;
} else if (typeof globalThis !== 'undefined' && globalThis.__TEMPORAL_ACTIVATOR__) {
  // we're executing within a temporal sandbox, which does not have access to env vars by default
  throw new Error('Temporal does not have access to env vars');
} else {
  throw new Error('Unable to resolve env vars');
}

export default EnvironmentVariables;
