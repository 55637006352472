import Environment, {ResolveEnvironment} from '@/config/Environment';
import type {ResolveEnvironmentParams} from '@/config/Environment';
import type {User} from '@/model/user/types';

const environment = ResolveEnvironment({
  Local: 'local',
  CI: 'ci',
  Dev: 'development',
  Prod: 'production',
});

export const isSentryEnabled = ResolveEnvironment({
  Local: false,
  CI: false,
  Dev: true,
  Prod: true,
  // force these types in case we want to override this locally temporarily such that compilation will catch this
} as Omit<ResolveEnvironmentParams<boolean>, 'Local'> & {Local: false});

export default class ErrorLogger {
  static _Sentry: any = null;

  static Initialize(Sentry, {integrationsToAdd, ...sentryConfig}: any = {}) {
    ErrorLogger._Sentry = Sentry;
    if (isSentryEnabled) {
      if (Environment.IsServer || Environment.IsLocal) {
        console.log(`initializing Sentry with env "${environment}" ...`);
      }
      Sentry.init({
        tracesSampleRate: 1,
        ...sentryConfig,
        environment,
        integrations(integrations) {
          // TODO bun-node-issue
          // filter out http integration because it is broken in bun right now
          // @see https://github.com/getsentry/sentry-javascript/issues/12891
          integrations = integrations.filter((integration) => {
            return integration.name !== 'Http';
          });
          try {
            const onUncaughtExceptionIntegration = integrations.find((integration) => {
              return integration.name === 'OnUncaughtException';
            });

            if (onUncaughtExceptionIntegration) {
              // this is only defined in node env.
              // we do not want sentry to exist here on uncaught exceptions
              onUncaughtExceptionIntegration._options = {
                ...onUncaughtExceptionIntegration._options,
                exitEvenIfOtherHandlersAreRegistered: false,
              };
            }

            if (integrationsToAdd?.length) {
              integrations.push(...integrationsToAdd);
            }

            return integrations;
          } catch (error) {
            // if any of this errors, errors won't go to sentry
            console.error(error);
          }
        },
        beforeSend(event, hint) {
          const {_sentryErrorId: _, ...rest} = hint.originalException;
          event.extra = {
            ...rest, // this will attach all arbitrary keys to sentry exceptions
            ...(event.extra || {}),
          };
          return event;
        },
      });
    } else {
      if (Environment.IsServer || Environment.IsCI) {
        console.log(`Sentry is currently disabled in env "${environment}"`);
      }
    }
  }

  static Log(
    error: Error,
    captureExceptionOptions: {tags?: Record<string, string>} = {},
    captureException: typeof ErrorLogger._Sentry.captureException = ErrorLogger._Sentry!
      .captureException,
  ) {
    if (error._ignoreLog) {
      return 'ignored-error-id';
    }
    if (error._sentryErrorId) {
      return error._sentryErrorId;
    }
    let sentryErrorId: string;
    if (isSentryEnabled) {
      sentryErrorId = captureException(error, captureExceptionOptions);
      if (Environment.IsServer) {
        console.error('Sentry error captured with ID:', sentryErrorId);
      }
    } else {
      // this will be defined in server because we set in on global
      // @ts-expect-error this is declared in server types.d.ts, but not sure how to declare it in shared generically so that it doesn't conflict
      if (typeof util !== 'undefined' && error.request) {
        // @ts-expect-error this is declared in server types.d.ts, but not sure how to declare it in shared generically so that it doesn't conflict
        console.error(util.inspect(error, false, null, true));
      } else {
        console.error(error);
      }
      sentryErrorId = '00000000000000000000000000000000';
    }
    error._sentryErrorId = sentryErrorId;
    return sentryErrorId;
  }

  // TODO this won't work in browser i'm pretty sure
  static async WithAsyncContext<T>(
    fn: () => Promise<T>,
    options: {tags?: Record<string, string>; extra?: Record<string, any>},
  ): Promise<T> {
    const {tags, extra} = options;
    const Sentry = ErrorLogger._Sentry;
    return await Sentry.withIsolationScope(async (scope) => {
      if (tags) {
        scope.setTags(tags);
      }
      if (extra) {
        // this isn't working for some reason, so just comment it out for now
        // const {type, ...rest} = context;
        // scope.setContext('custom-context', {
        //   // `type` is restricted within sentry, so rename it
        //   ...(type ? {_type: type} : {}),
        //   ...rest,
        // });
        scope.setExtras(extra);
      }

      return await fn();
    });
  }

  static async Flush() {
    const Sentry = ErrorLogger._Sentry;
    if (Sentry) {
      return Sentry.flush();
    }
  }

  // TODO split out ErrorLogger impl to one that can be extended on frontend; setting user is not defined on backend
  static SetUser(user: User | null) {
    if (user) {
      this._Sentry.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      this._Sentry.setUser(null);
    }
  }
}
